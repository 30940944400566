<script setup lang="ts">
import { TabsList, type TabsListProps } from 'radix-vue'
import { tv, type VariantProps } from 'tailwind-variants'
import { computed, type HTMLAttributes } from 'vue'

type ListVariants = VariantProps<typeof listVariants>

const props = defineProps<TabsListProps & {
  class?: HTMLAttributes['class']
  variant?: ListVariants['variant']
  align?: ListVariants['align']
}>()

const listVariants = tv({
  base: 'relative shrink-0',
  variants: {
    variant: {
      underline: 'mx-4',
      rounded: 'mx-4 rounded-full border border-gray-3',
    },
    align: {
      stretch: 'flex',
      center: 'inline-block whitespace-nowrap',
    },
  },
  defaultVariants: {
    variant: 'underline',
    align: 'stretch',
  },
})

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <TabsList
    v-bind="delegatedProps"
    class="cs-tablist -mx-4 -my-1 overflow-auto py-1 text-center"
  >
    <div :class="listVariants({ variant, align, class: props.class })">
      <slot />
    </div>
  </TabsList>
</template>

<style scoped>
.cs-tablist {
  /* Hide scrollbar (Firefox) */
  scrollbar-width: none;

  /* Hide scrollbar (WebKit) */
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
