<script setup lang="ts">
import { TabsTrigger, type TabsTriggerProps, useForwardProps } from 'radix-vue'
import { tv, type VariantProps } from 'tailwind-variants'
import { computed, type HTMLAttributes } from 'vue'

type TriggerVariants = VariantProps<typeof triggerVariants>

const props = defineProps<TabsTriggerProps & {
  class?: HTMLAttributes['class']
  variant?: TriggerVariants['variant']
}>()

const triggerVariants = tv({
  base: 'shrink-0 grow basis-[90px] font-semibold hover:text-blue-dark active:outline-none data-[state=active]:text-blue',
  variants: {
    variant: {
      underline: 'border-b border-gray-3 px-4 pb-[11px] pt-3',
      rounded: 'px-2 py-[7px] text-sm',
    },
  },
  defaultVariants: {
    variant: 'underline',
  },
})

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <TabsTrigger
    v-bind="forwardedProps"
    :class="triggerVariants({ variant, class: props.class })"
  >
    <slot />
  </TabsTrigger>
</template>
