<script setup lang="ts">
import { TabsIndicator, type TabsIndicatorProps } from 'radix-vue'
import { tv, type VariantProps } from 'tailwind-variants'
import { computed, type HTMLAttributes } from 'vue'

type IndicatorVariants = VariantProps<typeof indicatorVariants>

const props = defineProps<TabsIndicatorProps & {
  class?: HTMLAttributes['class']
  variant?: IndicatorVariants['variant']
}>()

const indicatorVariants = tv({
  base: '',
  variants: {
    variant: {
      underline: 'size-full bg-blue',
      rounded: 'size-full rounded-full border-2 border-blue',
    },
  },
  defaultVariants: {
    variant: 'underline',
  },
})

const indicatorWrapperVariants = tv({
  base: 'pointer-events-none absolute translate-x-[--radix-tabs-indicator-position] transition-[width,transform] duration-300',
  variants: {
    variant: {
      underline: 'bottom-0 left-0 z-1 h-[2px] w-[--radix-tabs-indicator-size]',
      rounded: '-inset-y-px -left-px w-[calc(var(--radix-tabs-indicator-size)+2px)]',
    },
  },
  defaultVariants: {
    variant: 'underline',
  },
})

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <!-- :class="tv({ base: 'm-10 size-10' })({ class: props.class })" -->
  <TabsIndicator
    v-bind="delegatedProps"
    :class="indicatorWrapperVariants({ variant, class: props.class })"
  >
    <div
      v-if="!$slots.default"
      :class="indicatorVariants({ variant, class: props.class })"
      class=""
    />
    <slot />
  </TabsIndicator>
</template>
